import easybank from '../../assets/images/easybank.png';
import crypto from '../../assets/images/crypto.png';
import covid from '../../assets/images/covid.png';
import mern from '../../assets/images/mern-clothing.png';

export default [
  {
    projectScreenshot: mern,
    projectBackground: 'bg-project2',
    projectTitle: 'MERN Clothing Store',
    projectDescription:
      "Fully functional e-commerce website built using the MERN stack. Stripe.js was used to receive and process orders from the user. Products and their images/details were sourced from the Arc'teryx web store.",
    projectLink:
      'https://polar-waters-95266.herokuapp.com/',
    projectCode:
      'https://github.com/matthew-io/mern-shopping',
    projectTech: 'MongoDB, Express, React, Node, Stripe.js',
  },
  {
    projectScreenshot: covid,
    projectBackground: 'bg-project1',
    projectTitle: 'COVID-19 Vaccination Statistics Web App',
    projectDescription:
      'Web app built in React for displaying data regarding the global rollout of covid vaccinations. Data is pulled from the Our World in Data API.',
    projectLink:
      'https://react-covid-vaccinations.netlify.app/',
    projectCode:
      'https://github.com/matthew-io/react-covid-vaccine',
    projectTech:
      'React, React Hooks, Our World In Data API',
  },
  {
    projectScreenshot: crypto,
    projectBackground: 'bg-project2',
    projectTitle: 'Cryptocurrency Wallet Website',
    projectDescription:
      'Fully responsive website built in React for a fictitious cryptocurrency wallet, "Coinsafe". The Cryptocompare API was used to pull data about specific cryptocurrencies. React Hooks like useEffect, useState and useCallback were utilised in order to fetch and display this information.',
    projectLink:
      'https://cranky-liskov-be72cb.netlify.app/',
    projectCode:
      'https://github.com/matthew-io/react-crypto-wallet',
    projectTech: 'React, React Hooks, Cryptocompare API',
  },
  {
    projectScreenshot: easybank,
    projectBackground: 'bg-project1',
    projectTitle: 'Banking Landing Page',
    projectDescription: `Fully responsive landing page built in vanilla HTML, CSS & JS for a fictitious banking company, 'Easybank'. This project was built as a solution to a Frontend Mentor problem, a repo of other solutions I have created can be found on my GitHub.`,
    projectLink: 'https://lucid-minsky-7ed414.netlify.app/',
    projectCode:
      'https://github.com/matthew-io/frontendmentor-easybank',
    projectTech: 'HTML5, CSS3 & JS',
  },
];
