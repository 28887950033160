import React from "react";
import { AiFillGithub, AiFillLinkedin, AiFillMail } from "react-icons/ai";

const LinksBar = () => {
  return (
    <div className="relative w-full bg-links pb-2 md:pb-10 pt-2">
      <div className="flex flex-row justify-center md:float-right md:mr-8 text-white">
        <a
          href="https://www.linkedin.com/in/matthew-mcconnachie-9759b51b1"
          target="_blank"
          aria-label="LinkedIn"
        >
          <AiFillLinkedin className="text-3xl text-gray-300 hover:text-white transition duration-300 ease-in-out" />
        </a>

        <a
          href="mailto:mattmcconnachie4@gmail.com"
          target="_blank"
          aria-label="E-mail"
        >
          <AiFillMail className="ml-4 mr-4 md:ml-8 md:mr-8 text-3xl text-gray-300 hover:text-white transition duration-300 ease-in-out" />
        </a>

        <a
          href="https://github.com/matthew-io"
          target="_blank"
          aria-label="github"
        >
          <AiFillGithub className="text-3xl text-gray-300 hover:text-white transition duration-300 ease-in-out" />
        </a>
      </div>
    </div>
  );
};

export default LinksBar;
