import React from 'react';

const Footer = () => {
  return (
    <div className="bg-project2 p-5">
      <div className="md:ml-32 text-center font-mono text-white">
        Matthew McConnachie © 2020
        <a
          href="https://github.com/matthew-io/portfolio"
          target="_blank"
          className="float-right justify-end capitalize border-2 -mt-1 p-1 hover:bg-white hover:text-black transition duration-300 text-sm ease-in-out"
        >
          Source code
        </a>
      </div>
    </div>
  );
};

export default Footer;
