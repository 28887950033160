import React from 'react';
import '../styles/about.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  Aos.init();

  window.addEventListener('load', Aos.refresh);

  return (
    <div className="font-mono about w-full">
      <div
        className="absolute lg:w-containerW z-50 md:ml-6 lg:ml-24 mt-16 md:mt-44"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <h1 className="capitalize text-4xl ml-6 mt-6 header text-text font-bold md:text-6xl">
          matthew mcConnachie
        </h1>
        <p className="mt-4 text-lg ml-6 mr-6 text-white md:text-xl md:mt-4">
          My name is Matthew McConnachie. I am a 21 year old
          front-end developer, currently based in Dundee,
          Scotland. See some of my projects below.
        </p>
      </div>
      <div className="bg-black">
        <div className="bg-black bg-img h-96 md:h-64 md:h-imageH bg-nyBg bg-cover bg-center w-full"></div>
      </div>
    </div>
  );
};

export default About;
