import React from "react";

const Nav = () => {
  return (
    <div className="w-full bg-nav pt-2 pb-2">
      <div className="text-white font-mono flex flex-row justify-center">
        <h1 className="border-b border-blue-600 cursor-pointer">Projects</h1>
        <a href="mailto:mattmcconnachie4@gmail.com">
          <h1 className="border-b border-transparent hover:border-blue-600 transition-0.3 duration-300 ease-in-out cursor-pointer ml-12 md:ml-16">
            Contact
          </h1>
        </a>
      </div>
    </div>
  );
};

export default Nav;
