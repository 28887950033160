import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import About from "./components/About";
import LinksBar from "./components/LinksBar";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

ReactDOM.render(
  <React.StrictMode>
    <About />
    <LinksBar />
    <Nav />
    <Projects />
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);
