import projectData from './data/projectData';

const Project = () => {
  return (
    <div className="projects">
      {projectData.map((project) => {
        const {
          projectScreenshot,
          projectTitle,
          projectDescription,
          projectLink,
          projectCode,
          projectBackground,
          projectTech,
        } = project;
        return (
          <div className={projectBackground}>
            <div className="pl-8 pr-8 pb-8 pt-8 flex flex-col justify-center items-center md:flex-row container">
              <div className="w-80 md:w-96 md:h-64">
                {' '}
                <img
                  src={projectScreenshot}
                  alt="Project Screenshot"
                  className="rounded-sm object-cover object-top w-full h-48 mt-8 shadow-md hover:shadow-lg trans-3"
                />
              </div>

              <div className="md:w-descriptionW mr-8 ml-8 md:mr-0 mt-4 md:pt-0 text-center md:text-left md:ml-12 font-mono md:mt-8">
                <h2 className="w-72 ml-auto mr-auto md:w-full md:border-l-2 md:pl-4 md:border-blue-500 text-white text-2xl">
                  {projectTitle}
                </h2>
                <p className="w-72 md:w-full ml-auto mr-auto text-base text-white mt-6">
                  {projectDescription}
                </p>
                <p className="w-72 ml-auto mr-auto md:w-full mt-6 text-white">
                  Technologies Used:
                  <span className="text-blue-500 ml-2">
                    {projectTech}
                  </span>
                </p>
                <div className="flex-row flex justify-center md:justify-start">
                  <a href={projectLink} target="_blank">
                    <p className="text-white mt-6 underline cursor-pointer hover:text-blue-500 transition duration=300 ease-in-out">
                      Live Demo
                    </p>
                  </a>
                  <a href={projectCode} target="_blank">
                    <p className="text-base text-white mt-6 underline cursor-pointer hover:text-blue-500 transition duration=300 ease-in-out ml-6">
                      Code
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Project;
